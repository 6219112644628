export const getEstimatedTimeslot = (duration) => {
  const totalSeconds = parseInt(duration, 10);
  let hours = Math.floor(totalSeconds / 3600); // get hours
  let minutes = Math.floor((totalSeconds - hours * 3600) / 60); // get minutes
  let seconds = totalSeconds - hours * 3600 - minutes * 60; //  get seconds
  // add 0 if value < 10;
  if (hours < 10) {
    hours = '0' + hours;
  }
  if (minutes < 10) {
    minutes = '0' + minutes;
  }
  if (seconds < 10) {
    seconds = '0' + seconds;
  }
  const time = hours > 0 ? `ETA ${Math.round(hours)} hr ${minutes} min` : `ETA ${Math.round(minutes)} min ${seconds} sec`;
  return time;
};



