import { createStore } from 'redux';

const initial_state = {
  tenant: {},
  tenantConfig: {},
  tenantLogo: '',
};

const appReducer = (state = initial_state, action) => {
  switch (action.type) {
    case 'TENANT':
      return { ...state, tenant: action.payload };
    case 'TENANT_CONFIG':
      return { ...state, tenantConfig: action.payload };
    case 'TENANT_LOGO':
      return { ...state, tenantLogo: action.payload };
    case 'MAP_STYLE':
      return { ...state, tracking_mapstyle: action.payload };
    default:
      return state;
  }
};

const loadState = () => {
  try {
    const serializedState = localStorage.getItem('ph-tracker');
    if (!serializedState) return undefined;
    else return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};

const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem('ph-tracker', serializedState);
  } catch (err) {
    console.log(err);
  }
};

const store = createStore(appReducer, loadState(), window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());

store.subscribe(() => {
  saveState(store.getState());
});

export default store;
