import axios from 'axios';
const BASE_URL = process.env.REACT_APP_API_URL;

const getRedirectUrl = async (id) => {
  try {
    const URL = BASE_URL.concat(`public/url/${id}`);
    const data = await axios.get(URL);
    return data;
  } catch (err) {
    return err;
  }
};

const getShipmentById = async (id) => {
  try {
    const URL = BASE_URL.concat(`public/shipment/${id}`);
    const data = await axios.get(URL);
    return data;
  } catch (err) {
    return err;
  }
};

const getShipmentByStatus = async (id) => {
  try {
    const URL = BASE_URL.concat(`public/shipment/${id}/status`);
    const data = await axios.get(URL);
    return data;
  } catch (err) {
    return err;
  }
};

const getFeedbackById = async (id) => {
  try {
    const URL = BASE_URL.concat(`public/shipment/${id}/feedback`);
    const data = await axios.get(URL);
    return data;
  } catch (err) {
    return err;
  }
};

const refreshToken = async (token) => {
  axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  try {
    const URL = BASE_URL.concat('auth/refresh');
    const data = await axios.post(URL);
    return data;
  } catch (err) {
    return err;
  }
};

const postFeedbackById = async (id, token, feedback) => {
  axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  try {
    const URL = BASE_URL.concat(`shipment/${id}/feedback`);
    const data = await axios.post(URL, feedback);
    return data;
  } catch (err) {
    return err;
  }
};

const updateDeliveryRequest = async (id, token, deliveryRequest) => {
  axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  try {
    const URL = BASE_URL.concat(`shipment/${id}/type`);
    const data = await axios.post(URL, deliveryRequest);
    return data;
  } catch (err) {
    return err;
  }
};

export { refreshToken, getFeedbackById, getRedirectUrl, getShipmentById, getShipmentByStatus, postFeedbackById ,updateDeliveryRequest};
