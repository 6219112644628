import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import DeviceDetector from 'device-detector-js';

import Spinner from '../components/spinner';

const OnBoard = (props) => {
  const AndriodDownloadLink = 'http://play.google.com/store/apps/details?id=com.phoxhealth.driver';
  const IosDownloadLink = 'http://apps.apple.com/us/app/phox-tracker/id1597703906';
  
  const [loading, showSpinner] = useState(false);

  useEffect(() => {
    document.title = `Welcome to Phox health`;
    getInitialData();
  }, []);

  const getInitialData = async () => {
    showSpinner(true);
    const deviceDetector = new DeviceDetector();
    const userAgent = window.navigator.userAgent;
    const device = deviceDetector.parse(userAgent);
    const os = device?.os?.name?.toLowerCase();
    if (os === 'android') {
      window.location.href = AndriodDownloadLink;
    } else {
      window.location.href = IosDownloadLink;
    }
    showSpinner(false);
  };

  const { tenantLogo } = props;

  return (
    <div id="custom-body" className="d-flex align-items-center bg-auth border-top border-top-2 border-primary vh-100">
      <div className="container">
        <div className="row justify-content-center text-center">
          <div className="col col-md-6">
            <div className="card">
              <div className="card-body">
                <img src={tenantLogo} alt="logo" className="img-fluid" />
                <Spinner display={loading} logo={tenantLogo}>
                  <h1 className="display-4 mb-3 mt-5">Welcome!</h1>
                  Any issues, contact <a href="tel:(707) 968-7469"> (707) 968-7469 </a>
                  for help. Thank You!!
                </Spinner>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    tenantLogo:state.tenantLogo
  };
};

export default connect(mapStateToProps)(OnBoard);
