import React from 'react';

const Spinner = ({ display, children, logo }) => {
  return (
    <>
      {display ? (
        <div className='container'>
          <div className='row align-items-center vh-100'>
            <div className='col-12 col-md-8 offset-md-2 col-lg-6 offset-lg-3 text-center'>
              <img src={logo} className='img-fluid mb-6 mb-md-0 aos-init aos-animate' alt='...' data-aos='fade-up' data-aos-delay='100' />
              <div className='progress mt-4'>
                <div className='progress-bar progress-bar-striped progress-bar-animated bg-ph-primary' role='progressbar' style={{ width: '100%' }} aria-valuenow='75' aria-valuemin='0' aria-valuemax='100'></div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>{children}</>
      )}
    </>
  );
};

export default Spinner;
