import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import Spinner from '../components/spinner';
import { getRedirectUrl } from '../services/index';
import { trackPageView } from '../helpers/analytics';

const UrlRedirect = (props) => {
  useEffect(() => {
    document.title = `Redirect`;
    trackPageView();
    redirectUrl();
  });

  const redirectUrl = async () => {
    const response = await getRedirectUrl(props.match.params.id);
    if (response && response.data) {
      window.location.href = response.data.url;
    }
  };

  const { tenantLogo } = props;

  return (
    <div className="d-flex align-items-center bg-auth border-top border-top-2 border-primary vh-100">
      <div className="container">
        <div className="row justify-content-center text-center">
          <div className="col col-md-6">
            <div className="card">
              <div className="card-body">
                <Spinner display={true} logo={tenantLogo}></Spinner>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};


const mapStateToProps = (state) => {
  return {
    tenantLogo:state.tenantLogo
  };
};

export default connect(mapStateToProps)(UrlRedirect);
