import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { isEmpty } from 'lodash';
import { Map, Marker, GoogleApiWrapper } from 'google-maps-react';

import firebaseApp from './../helpers/firebaseApp';
import { getEstimatedTimeslot } from '../helpers/utils';
import { getShipmentById, getShipmentByStatus } from '../services/index';
import { defaultMapStyle, renownMapStyle, sabanMapStyle, swedishMapStyle } from './mapStyle';

const hostname = window.location.hostname.split('.')[0];

const LiveTracker = (props) => {
  const [shipment, setShipment] = useState({});
  const [driver, setDriver] = useState({});
  const [bounds, setBounds] = useState([]);
  const [loadDetails, setLoadDetails] = useState(false);
  const [deliveryProofs, setDeliveryProofs] = useState([]);
  const [deliveryMessage, setDeliveryMessage] = useState('');
  const [isVerifiedWithOtp, setVerifiedWithOtp] = useState(false);

  useEffect(() => {
    firebaseApp
      .firestore()
      .collection('shipments')
      .where('id', '==', props.match.params.id)
      .onSnapshot((objects) => {
        let shipment = {};
        let pointsToPlot = [];

        objects.forEach((item) => {
          shipment = item.data();
        });

        if (!isEmpty(shipment)) {
          shipment.ship_from_location = {
            lat: shipment.ship_from && Number(shipment.ship_from.location?._latitude),
            lng: shipment.ship_from && Number(shipment.ship_from.location?._longitude),
          };

          shipment.ship_to_location = {
            lat: shipment.ship_to && Number(shipment.ship_to.location?._latitude),
            lng: shipment.ship_to && Number(shipment.ship_to.location?._longitude),
          };

          document.title = shipment?.ship_from ? `${shipment?.shipper_name} - Live Tracker` : 'Live Tracker';
          pointsToPlot.push(shipment.ship_from_location, shipment.ship_to_location);
          console.log('shipment', shipment);
          setShipment(shipment);

          if (shipment?.status === 'Out for Delivery' && shipment?.driver_id) {
            firebaseApp
              .firestore()
              .collection('driver_v2')
              .where('id', '==', shipment.driver_id)
              .onSnapshot((objects) => {
                let driver = [];
                objects.forEach((object) => {
                  driver = object.data();
                });
                driver.location = {
                  lat: driver?.latitude && Number(driver.latitude),
                  lng: driver?.longitude && Number(driver.longitude),
                };
                pointsToPlot.push(driver.location);
                console.log('driver', driver);
                setDriver(driver);
              });
          }
          const points = pointsToPlot.filter((item) => item.lat && item.lng);
          let bounds = new props.google.maps.LatLngBounds();
          points.forEach((point) => {
            bounds.extend(point);
          });
          setBounds(bounds);
        } else {
          getShipmentDetails();
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getShipmentDetails = async () => {
    const response = await getShipmentById(props.match.params.id);
    if (response && response.data) {
      let pointsToPlot = [];
      let shipment = response?.data;
      if (shipment.status === 'Delivered') {
        getShipmentPOD();
      }
      shipment.ship_from_location = {
        lat: shipment.ship_from && Number(shipment.ship_from.location?._latitude),
        lng: shipment.ship_from && Number(shipment.ship_from.location?._longitude),
      };

      shipment.ship_to_location = {
        lat: shipment.ship_to && Number(shipment.ship_to.location?._latitude),
        lng: shipment.ship_to && Number(shipment.ship_to.location?._longitude),
      };

      document.title = shipment?.ship_from ? `${shipment?.shipper_name} - Live Tracker` : 'Live Tracker';
      pointsToPlot.push(shipment.ship_from_location, shipment.ship_to_location);
      const points = pointsToPlot.filter((item) => item.lat && item.lng);
      let bounds = new props.google.maps.LatLngBounds();
      points.forEach((point) => {
        bounds.extend(point);
      });
      setBounds(bounds);
      setShipment(shipment || {});
      document.title = response?.data?.ship_from ? `${response.data?.shipper_name} - Live Tracker` : 'Live Tracker';
    }
  };

  const getShipmentPOD = async () => {
    const response = await getShipmentByStatus(props.match.params.id);
    if (response && response.data) {
      const verificationCode = shipment?.verification_code;
      const isDelivered = !!response?.data.find((item) => item.status === 'Delivered');
      const message = response?.data.find((item) => item.status === 'Delivered')?.description;
      if (isDelivered) {
        let data = response?.data.find((item) => Array.isArray(item?.attachments) && item?.attachments?.length);
        if (data) {
          if (data.attachments[0].includes('{"url"')) {
            data = JSON.parse(data.attachments[0]);
            data = Object.values(data);
            setDeliveryProofs(data);
          } else {
            const proofDetails = data?.attachments;
            if (Array.isArray(proofDetails) && proofDetails.length > 0) {
              setDeliveryProofs(proofDetails);
            }
          }
          setVerifiedWithOtp(false);
        } else if (Number(message) === verificationCode) {
          setDeliveryMessage(`Verified with OTP - ${verificationCode}`);
          setVerifiedWithOtp(true);
        } else {
          setDeliveryMessage(message);
          setVerifiedWithOtp(false);
        }
      }
    }
  };

  return (
    <div>
      <div className="fixed-top vh-100">
        <Map styles={props.mapStyles} google={props.google} bounds={bounds} containerStyle={props?.tracking_mapstyle} mapTypeControl={false} streetViewControl={false}>
          <Marker name="ship_from" icon={{ url: 'img/marker/ship_from.png' }} position={shipment.ship_from_location} />
          <Marker name="ship_to" icon={{ url: 'img/marker/ship_to.png' }} position={shipment.ship_to_location} />
          {shipment?.status === 'Out for Delivery' && <Marker name="driver" icon={{ url: 'img/marker/driver.png' }} position={driver.location} />}
        </Map>
      </div>
      <div className="fixed-top w-25 m-4 d-none d-lg-block">
        <div className="card mb-3">
          <div className="card-body p-3 text-center">
            {shipment?.status === 'Delivered' ? (
              <>
                Your package is <span className="fw-bold">{shipment?.status}</span>
                <i className="fe fe-check-circle text-success ms-1"></i>
              </>
            ) : (
              <div>
                Your package is <span className="fw-bold">{shipment?.status === 'Exception' ? 'Cancelled' : shipment?.status}</span>
              </div>
            )}
            {shipment?.status === 'Out for Delivery' && (
              <>
                {(shipment?.route_sequence !== 99 || shipment?.route_sequence !== 0) && <div>{shipment?.route_sequence === 1 ? <h3 className="mt-2 mb-2">The driver is on the way to your location </h3> : shipment?.route_sequence === 2 ? <h3 className="mt-2 mb-2">Your driver is 1 stop away</h3> : shipment?.route_sequence !== 99 && shipment?.route_sequence > 1 ? <h3 className="mt-2 mb-2">{`Your driver is ${shipment?.route_sequence - 1} Stops away`}</h3> : ''}</div>}
                <div>{shipment?.route_duration > 0 && <h2 className="alert-heading mb-2 mt-2">{Math.floor(shipment?.route_duration / 3600) < 5 ? getEstimatedTimeslot(shipment?.route_duration) : 'Standby for new ETA'}</h2>}</div>
              </>
            )}
            <ul id="progressbar" className="mt-4 mb-0">
              <li id="ship_from" className="active"></li>
              <li id="driver" className={shipment?.status === 'Out for Delivery' || shipment?.status === 'Delivered' ? 'active' : ''}></li>
              <li id="ship_to" className={shipment?.status === 'Delivered' ? 'active' : ''}></li>
            </ul>
          </div>
          {shipment.status === 'Delivered' && (
            <div className="card-footer pb-0">
              <p className="d-flex align-items-center justify-content-center">
                View more details <i className={`fe ${loadDetails ? 'fe-chevron-down' : 'fe-chevron-up'} ms-1`} onClick={() => setLoadDetails(!loadDetails)}></i>
              </p>
            </div>
          )}
        </div>
        <div className="card mb-3">
          <div className="card-body p-0">
            {shipment?.ship_from && (
              <div>
                <>
                  <div className="d-flex justify-content-between align-items-center mb-4 logo-padding">
                    <img src={props.tenantLogo} alt="logo" className="img-fluid" style={{ height: 'auto', width: '40%' }} />
                    {!shipment?.ship_from?.phone && (
                      <a className="badge rounded-pill bg-light px-3p pt-2" href={`tel://+19498443924`}>
                        <i className="fe fe-phone"></i> Call
                      </a>
                    )}
                  </div>
                  <hr />
                </>
                <div className="d-flex justify-content-between localtion-padding">
                  <small className="text-muted">
                    <span className="h3 text-danger me-1">●</span>Pick Up Location
                  </small>
                </div>
                <div className="ms-3 localtion-padding">
                  <div className="mt-2">{shipment.ship_from?.shipper_name}</div>
                  {shipment.ship_from?.address2?.length > 0 ? (
                    <div className="text-muted">
                      {shipment.ship_from?.address1} {shipment.ship_from?.address2}, {shipment.ship_from?.city}, {shipment.ship_from?.state} {shipment.ship_from?.postal_code}
                    </div>
                  ) : (
                    <div className="text-muted">
                      {shipment.ship_from?.address1}, {shipment.ship_from?.city}, {shipment.ship_from?.state} {shipment.ship_from?.postal_code}
                    </div>
                  )}
                </div>
              </div>
            )}
            <div className="localtion-padding">
              <hr />
            </div>
            {shipment.ship_to && (
              <div style={{ padding: '0px 20px 20px 20px' }}>
                <small className="text-muted">
                  <span className="h3 text-success me-1">●</span>Drop Off Location
                </small>
                <div className="ms-3">
                  <div className="mt-2">{shipment.ship_to.contact_name}</div>
                  {shipment.ship_to?.address2?.length > 0 ? (
                    <div className="text-muted">
                      {shipment.ship_to?.address1} {shipment.ship_to?.address2}, {shipment.ship_to?.city}, {shipment.ship_to?.state} {shipment.ship_to?.postal_code}
                    </div>
                  ) : (
                    <div className="text-muted">
                      {shipment.ship_to?.address1}, {shipment.ship_to?.city}, {shipment.ship_to?.state} {shipment.ship_to?.postal_code}
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
        {loadDetails && shipment.status === 'Delivered' && (
          <div className="card">
            <div className="card-body p-3">
              Proof of Delivery
              <div className="mt-2">
                {isVerifiedWithOtp ? (
                  <h3 className="header-title">{deliveryMessage?.length > 0 ? deliveryMessage : ''}</h3>
                ) : deliveryProofs.length > 0 ? (
                  <div className="d-flex">
                    {deliveryProofs?.map((proof, index) => {
                      return (
                        <div className={index === 0 ? '' : 'ms-3'}>
                          <a href={proof} target="_blank" without rel="noopener noreferrer">
                            <img src={`${proof}`} alt="no" className="img-fluid" height="40" width="40" />
                          </a>
                        </div>
                      );
                    })}
                  </div>
                ) : deliveryMessage?.length > 0 ? (
                  deliveryMessage
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="fixed-bottom mx-5 d-lg-none">
        <div className="card mb-2">
          <div className="card-body p-3 text-center">
            {shipment?.status === 'Delivered' ? (
              <>
                Your package is <span className="fw-bold">{shipment?.status}</span>
                <i className="fe fe-check-circle text-success ms-1"></i>
              </>
            ) : (
              <div>
                Your package is <span className="fw-bold">{shipment?.status === 'Exception' ? 'Cancelled' : shipment?.status}</span>
              </div>
            )}
            {shipment?.status === 'Out for Delivery' && (
              <>
                {(shipment?.route_sequence !== 99 || shipment?.route_sequence !== 0) && <div>{shipment?.route_sequence === 1 ? <h3 className="mt-2 mb-2">The driver is on the way to your location </h3> : shipment?.route_sequence === 2 ? <h3 className="mt-2 mb-2">Your driver is 1 stop away</h3> : shipment?.route_sequence !== 99 && shipment?.route_sequence > 1 ? <h3 className="mt-2 mb-2">{`Your driver is ${shipment?.route_sequence - 1} Stops away`}</h3> : ''}</div>}
                <div>{shipment?.route_duration > 0 && <h2 className="alert-heading mb-2 mt-2">{Math.floor(shipment?.route_duration / 3600) < 5 ? getEstimatedTimeslot(shipment?.route_duration) : 'Standby for new ETA'}</h2>}</div>
              </>
            )}
            <ul id="progressbar" className="mt-4 mb-0">
              <li id="ship_from" className="active"></li>
              <li id="driver" className={shipment?.status === 'Out for Delivery' || shipment?.status === 'Delivered' ? 'active' : ''}></li>
              <li id="ship_to" className={shipment?.status === 'Delivered' ? 'active' : ''}></li>
            </ul>
          </div>
          <div className="card-footer pb-0">
            <p className="d-flex align-items-center justify-content-center">
              View more details <i className={`fe ${loadDetails ? 'fe-chevron-down' : 'fe-chevron-up'} ms-1`} onClick={() => setLoadDetails(!loadDetails)}></i>
            </p>
          </div>
        </div>
        {loadDetails && (
          <div className="card mb-3">
            <div className="card-body p-0">
              {shipment?.ship_from && (
                <div>
                  <>
                    <div className="d-flex justify-content-between align-items-center mb-4 logo-padding">
                      <img src={props.tenantLogo} alt="logo" className="img-fluid" style={{ height: 'auto', width: '40%' }} />
                      {!shipment?.ship_from?.phone && (
                        <a className="badge rounded-pill bg-light px-3p pt-2" href={`tel://${shipment.ship_from.phone}`}>
                          <i className="fe fe-phone"></i> Call
                        </a>
                      )}
                    </div>
                    <hr />
                  </>
                  <div className="d-flex justify-content-between localtion-padding">
                    <small className="text-muted">
                      <span className="h3 text-danger me-1">●</span>Pick Up Location
                    </small>
                    {/* {!shipment?.ship_from?.phone && (
                      <a className="badge rounded-pill bg-light px-3" href={`tel://${shipment.ship_from.phone}`}>
                        <i className="fe fe-phone"></i> Call
                      </a>
                    )} */}
                  </div>
                  <div className="ms-3 localtion-padding">
                    <div className="mt-2">{shipment.ship_from?.shipper_name}</div>
                    {shipment.ship_from?.address2?.length > 0 ? (
                      <div className="text-muted">
                        {shipment.ship_from?.address1} {shipment.ship_from?.address2}, {shipment.ship_from?.city}, {shipment.ship_from?.state} {shipment.ship_from?.postal_code}
                      </div>
                    ) : (
                      <div className="text-muted">
                        {shipment.ship_from?.address1}, {shipment.ship_from?.city}, {shipment.ship_from?.state} {shipment.ship_from?.postal_code}
                      </div>
                    )}
                  </div>
                </div>
              )}
              <div className="localtion-padding">
                <hr />
              </div>
              {shipment.ship_to && (
                <div style={{ padding: '0px 20px 20px 20px' }}>
                  <small className="text-muted">
                    <span className="h3 text-success me-1">●</span>Drop Off Location
                  </small>
                  <div className="ms-3">
                    <div className="mt-2">{shipment.ship_to.contact_name}</div>
                    {shipment.ship_to?.address2?.length > 0 ? (
                      <div className="text-muted">
                        {shipment.ship_to?.address1} {shipment.ship_to?.address2}, {shipment.ship_to?.city}, {shipment.ship_to?.state} {shipment.ship_to?.postal_code}
                      </div>
                    ) : (
                      <div className="text-muted">
                        {shipment.ship_to?.address1}, {shipment.ship_to?.city}, {shipment.ship_to?.state} {shipment.ship_to?.postal_code}
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
        {loadDetails && shipment.status === 'Delivered' && (
          <div className="card">
            <div className="card-body p-3">
              Proof of Delivery
              <div className="mt-2">
                {isVerifiedWithOtp ? (
                  <h3 className="header-title">{deliveryMessage?.length > 0 ? deliveryMessage : ''}</h3>
                ) : deliveryProofs.length > 0 ? (
                  <div className="d-flex">
                    {deliveryProofs?.map((proof, index) => {
                      return (
                        <div className={index === 0 ? '' : 'ms-3'}>
                          <a href={proof} target="_blank" without rel="noopener noreferrer">
                            <img src={`${proof}`} alt="no" className="img-fluid" height="40" width="40" />
                          </a>
                        </div>
                      );
                    })}
                  </div>
                ) : deliveryMessage?.length > 0 ? (
                  deliveryMessage
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
    shipper: state.shipper,
    shippers: state.shippers,
    tracking_mapstyle: state.tracking_mapstyle,
    tenantLogo: state.tenantLogo,
  };
};

LiveTracker.defaultProps = {
  mapStyles: hostname === 'rh' ? renownMapStyle : hostname === 'sc' ? sabanMapStyle : hostname === 'sw' ? swedishMapStyle : defaultMapStyle,
};

LiveTracker.defaultProps = {
  mapStyles: defaultMapStyle,
};

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  libraries: ['places', 'visualization'],
})(connect(mapStateToProps)(LiveTracker));
