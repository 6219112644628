
  
  export const renownMapStyle = [
    {
      featureType: 'landscape',
      elementType: 'all',
      stylers: [
        {
          hue: '#6600ff',
        },
        {
          saturation: -11,
        },
        {
          visibility: 'on',
        },
      ],
    },
    {
      featureType: 'poi',
      elementType: 'all',
      stylers: [
        {
          saturation: '33',
        },
        {
          hue: '#6600ff',
        },
        {
          lightness: '2',
        },
        {
          visibility: 'simplified',
        },
      ],
    },
    {
      featureType: 'poi.attraction',
      elementType: 'all',
      stylers: [
        {
          saturation: '-58',
        },
        {
          lightness: '28',
        },
      ],
    },
    {
      featureType: 'poi.business',
      elementType: 'all',
      stylers: [
        {
          saturation: '-58',
        },
        {
          lightness: '28',
        },
      ],
    },
    {
      featureType: 'poi.government',
      elementType: 'all',
      stylers: [
        {
          saturation: '-58',
        },
        {
          lightness: '28',
        },
      ],
    },
    {
      featureType: 'poi.medical',
      elementType: 'all',
      stylers: [
        {
          saturation: '-58',
        },
        {
          lightness: '28',
        },
      ],
    },
    {
      featureType: 'poi.park',
      elementType: 'all',
      stylers: [
        {
          visibility: 'on',
        },
        {
          saturation: '-58',
        },
        {
          lightness: '28',
        },
      ],
    },
    {
      featureType: 'poi.place_of_worship',
      elementType: 'all',
      stylers: [
        {
          saturation: '-58',
        },
        {
          lightness: '28',
        },
      ],
    },
    {
      featureType: 'poi.school',
      elementType: 'all',
      stylers: [
        {
          saturation: '-58',
        },
        {
          lightness: '28',
        },
      ],
    },
    {
      featureType: 'poi.sports_complex',
      elementType: 'all',
      stylers: [
        {
          saturation: '-58',
        },
        {
          lightness: '28',
        },
      ],
    },
    {
      featureType: 'road',
      elementType: 'all',
      stylers: [
        {
          hue: '#5e00ff',
        },
        {
          saturation: -79,
        },
      ],
    },
    {
      featureType: 'road.arterial',
      elementType: 'labels.icon',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'road.local',
      elementType: 'all',
      stylers: [
        {
          lightness: 30,
        },
        {
          weight: 1.3,
        },
      ],
    },
    {
      featureType: 'transit',
      elementType: 'all',
      stylers: [
        {
          visibility: 'simplified',
        },
        {
          hue: '#5e00ff',
        },
        {
          saturation: -16,
        },
      ],
    },
    {
      featureType: 'transit.line',
      elementType: 'all',
      stylers: [
        {
          saturation: -72,
        },
      ],
    },
    {
      featureType: 'water',
      elementType: 'all',
      stylers: [
        {
          saturation: -65,
        },
        {
          hue: '#1900ff',
        },
        {
          lightness: 8,
        },
      ],
    },
  ];
  
  export const sabanMapStyle = [
    {
      featureType: 'landscape',
      elementType: 'all',
      stylers: [
        {
          hue: '#ffbb00',
        },
        {
          saturation: '-100',
        },
        {
          lightness: 37.599999999999994,
        },
        {
          gamma: 1,
        },
      ],
    },
    {
      featureType: 'landscape.natural.landcover',
      elementType: 'geometry',
      stylers: [
        {
          visibility: 'on',
        },
      ],
    },
    {
      featureType: 'poi',
      elementType: 'all',
      stylers: [
        {
          saturation: -1.0989010989011234,
        },
        {
          lightness: 11.200000000000017,
        },
        {
          gamma: 1,
        },
      ],
    },
    {
      featureType: 'poi',
      elementType: 'geometry',
      stylers: [
        {
          visibility: 'on',
        },
      ],
    },
    {
      featureType: 'poi',
      elementType: 'geometry.fill',
      stylers: [
        {
          color: '#90e1b0',
        },
      ],
    },
    {
      featureType: 'poi',
      elementType: 'geometry.stroke',
      stylers: [
        {
          visibility: 'on',
        },
      ],
    },
    {
      featureType: 'poi',
      elementType: 'labels',
      stylers: [
        {
          visibility: 'on',
        },
      ],
    },
    {
      featureType: 'poi',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#213d62',
        },
      ],
    },
    {
      featureType: 'poi',
      elementType: 'labels.text.stroke',
      stylers: [
        {
          gamma: '1',
        },
      ],
    },
    {
      featureType: 'poi',
      elementType: 'labels.icon',
      stylers: [
        {
          gamma: '1',
        },
        {
          visibility: 'simplified',
        },
        {
          saturation: '0',
        },
        {
          lightness: '0',
        },
        {
          hue: '#006eff',
        },
      ],
    },
    {
      featureType: 'poi.attraction',
      elementType: 'all',
      stylers: [
        {
          visibility: 'on',
        },
      ],
    },
    {
      featureType: 'poi.attraction',
      elementType: 'labels',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'poi.business',
      elementType: 'labels',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'poi.government',
      elementType: 'labels',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'poi.medical',
      elementType: 'labels',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'poi.park',
      elementType: 'labels',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'poi.place_of_worship',
      elementType: 'all',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'poi.place_of_worship',
      elementType: 'labels',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'poi.school',
      elementType: 'all',
      stylers: [
        {
          visibility: 'on',
        },
      ],
    },
    {
      featureType: 'poi.school',
      elementType: 'labels',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'poi.sports_complex',
      elementType: 'labels',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'road',
      elementType: 'all',
      stylers: [
        {
          saturation: '-100',
        },
      ],
    },
    {
      featureType: 'road.highway',
      elementType: 'all',
      stylers: [
        {
          hue: '#FFC200',
        },
        {
          saturation: -61.8,
        },
        {
          lightness: 45.599999999999994,
        },
        {
          gamma: 1,
        },
      ],
    },
    {
      featureType: 'road.arterial',
      elementType: 'all',
      stylers: [
        {
          hue: '#FF0300',
        },
        {
          saturation: -100,
        },
        {
          lightness: 51.19999999999999,
        },
        {
          gamma: 1,
        },
      ],
    },
    {
      featureType: 'road.local',
      elementType: 'all',
      stylers: [
        {
          hue: '#ff0000',
        },
        {
          saturation: '-100',
        },
        {
          lightness: 52,
        },
        {
          gamma: 1,
        },
        {
          visibility: 'on',
        },
      ],
    },
    {
      featureType: 'transit',
      elementType: 'all',
      stylers: [
        {
          saturation: '0',
        },
        {
          lightness: '0',
        },
      ],
    },
    {
      featureType: 'water',
      elementType: 'all',
      stylers: [
        {
          hue: '#0078FF',
        },
        {
          saturation: -13.200000000000003,
        },
        {
          lightness: 2.4000000000000057,
        },
        {
          gamma: 1,
        },
      ],
    },
    {
      featureType: 'water',
      elementType: 'geometry.fill',
      stylers: [
        {
          color: '#b4d9e3',
        },
      ],
    },
  ];

export const swedishMapStyle =
  [
    {
      "featureType": "all",
      "stylers": [
        {
          "saturation": 0
        },
        {
          "hue": "#7BAFD4"
        }
      ]
    },
    {
      "featureType": "road",
      "stylers": [
        {
          "saturation": -70
        }
      ]
    },
    {
      "featureType": "transit",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "poi",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "water",
      "stylers": [
        {
          "visibility": "simplified"
        },
        {
          "saturation": -60
        }
      ]
    }
  ];
  
  export const defaultMapStyle = [
    {
      featureType: 'administrative',
      elementType: 'geometry',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'poi',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'road',
      elementType: 'labels.icon',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'transit',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'all',
      stylers: [
        {
          saturation: 0,
        },
        {
          hue: '#e7ecf0',
        },
      ],
    },
    {
      featureType: 'road',
      stylers: [
        {
          saturation: -70,
        },
      ],
    },
    {
      featureType: 'transit',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'poi',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'water',
      stylers: [
        {
          visibility: 'simplified',
        },
        {
          saturation: -60,
        },
      ],
    },
  ];
  