import React, { useEffect }from 'react';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import Feedback from './pages/feedback';
import LiveTracker from './pages/liveTracker';
import UrlRedirect from './pages/urlRedirect';

import OnBoard from './pages/onBoard';

import firebaseApp from './helpers/firebaseApp';
import 'react-toastify/dist/ReactToastify.css';

const App = (props) => {
  const remoteConfig = firebaseApp.remoteConfig();

  useEffect(() => {
  firebaseApp.remoteConfig().fetchAndActivate()
    .then(() => {
      const host = window.location.hostname;
      const hosts = JSON.parse(remoteConfig.getString("hosts"));

      // Tenant
      const tenant = hosts.hasOwnProperty(host) ? hosts[host] : 'multicare';
      props.dispatch({ type: 'TENANT', payload: tenant });

      // Tenant Icon
      firebaseApp.storage().ref(`/img/${tenant}/icon.png`).getDownloadURL().then((url) => {
        document.getElementById('favicon').href = url;
      });

      // Tenant Logo
      firebaseApp.storage().ref(`/img/${tenant}/logo.png`).getDownloadURL().then((url) => {
        props.dispatch({ type: 'TENANT_LOGO', payload: url });
      });

      // Tenant Config
      const tenant_config = JSON.parse(remoteConfig.getString(`tenant_config_${tenant}`));
      props.dispatch({ type: 'TENANT_CONFIG', payload: tenant_config });

        //Tracking mapStyles
        const mapStyle = JSON.parse(remoteConfig.getString('tracking_mapstyle'));
        props.dispatch({ type: 'MAP_STYLE', payload: mapStyle });
    }).catch((err) => {
      console.log('remote config error', err)
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  return (
    <>
      <Router>
        <Switch>
          <Route exact path='/:id' component={LiveTracker} />
          <Route exact path='/u/:id' component={UrlRedirect} />
          <Route exact path='/:id/feedback/:token' component={Feedback} />
          <Route exact path='/w/onboarding/:id' component={OnBoard} />
        </Switch>
      </Router>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    tenantLogo: state.tenantLogo,
    tracking_mapstyle: state.tracking_mapstyle 
  };
};

export default connect(mapStateToProps)(App);